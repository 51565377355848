export const membersFilterAvailable = [
    {
        id: "identifier",
        label: "identifier",
        type: "string",
    },
    {
        id: "salutation",
        label: "salutation",
        type: "dictionaryEntry",
        dictionaryId: "salutation"
    },
    {
        id: "title",
        label: "title",
        type: "dictionaryEntry",
        dictionaryId: "members_title"
    },
    {
        id: "firstName",
        label: "firstName",
        type: "string",
    },

    {
        id: "lastName",
        label: "lastName",
        type: "string",
    },

    // dateOfBirth
    // dateOfDeath
    // lifePeriodComment

    {
        id: "retired",
        label: "retired",
        type: "bool",
    },

    {
        id: "dead",
        label: "dead",
        type: "bool",
    },


    {
        id: "homeAddress.street",
        label: "homeAddress.street",
        type: "string",
    },
    {
        id: "homeAddress.postalCode",
        label: "homeAddress.postalCode",
        type: "string",
    },
    {
        id: "homeAddress.city",
        label: "homeAddress.city",
        type: "dictionaryEntry",
        dictionaryId: "members_city"
    },
    {
        id: "homeAddress.country",
        label: "homeAddress.country",
        type: "dictionaryEntry",
        dictionaryId: "members_country"
    },


    {
        id: "correspondenceAddress.street",
        label: "correspondenceAddress.street",
        type: "string",
    },
    {
        id: "correspondenceAddress.postalCode",
        label: "correspondenceAddress.postalCode",
        type: "string",
    },
    {
        id: "correspondenceAddress.city",
        label: "correspondenceAddress.city",
        type: "dictionaryEntry",
        dictionaryId: "members_city"
    },
    {
        id: "correspondenceAddress.country",
        label: "correspondenceAddress.country",
        type: "dictionaryEntry",
        dictionaryId: "members_country"
    },


    {
        id: "speciality",
        label: "speciality",
        type: "dictionaryEntry",
        dictionaryId: "speciality"
    },


    //miejsce pracy


    {
        id: "committees",
        label: "committees",
        type: "dictionaryEntry",
        dictionaryId: "committees"
    },


    {
        id: "departments",
        label: "department",
        type: "dictionaryEntry",
        dictionaryId: "members_department"
    },

    {
        id: "membershipTypes",
        label: "membershipTypes",
        type: "dictionaryEntry",
        dictionaryId: "membership_type"
    },


    {
        id: "workPlaceInstitutionsByDictionaryToken",
        label: "institution",
        type: "dictionaryEntry",
        dictionaryId: "institution"
    },

    {
        id: "workplaceInstitutionToken",
        label: "instituteName",
        type: "string",
    },

    {
        id: "rolesTokenised",
        label: "role",
        type: "dictionaryEntry",
        dictionaryId: "members_role"
    },


];
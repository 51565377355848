import {getFilterValues} from "../reducers/filteringReducer";
import {ARTWORKS, DICTIONARY_ENTRIES, MEMBERS, PERSONS} from "../components/browse/BrowseType";

export const getSearchParams = (state, browseType, filtersAvailable) => {
    let searchParams = {
        page: state.browse[browseType].pageNumber,
        pageSize: state.browse[browseType].pageSize,
    };

    if (browseType == ARTWORKS || browseType == DICTIONARY_ENTRIES || browseType == PERSONS || browseType == MEMBERS) {
        searchParams.sortBy = state.browse[browseType].sortBy;
    }

    let filters = getFilterValues(state)

    let searchBy = {}

    filtersAvailable
        .filter(filterConfig => filters[filterConfig.id])
        .forEach(filterConfig => {
            searchBy[filterConfig.id] = prepareFilterValueForSearch(filterConfig, filters[filterConfig.id])
        })

    searchParams.search = searchBy;

    return searchParams;
};

function prepareFilterValueForSearch(filterConfig, value) {
    if (filterConfig.type == "string") {
        return value.value;
    } else if (filterConfig.type == "bool") {
        return value.value;
    } else if (filterConfig.type == "number") {
        return parseInt(value.value);
    } else if (filterConfig.type == "dictionaryEntry") {
        return value.value.map(v => v.dictionaryEntry.id);
    } else if (filterConfig.type == "folder") {
        return value.value.map(v => v.value.id);
    } else if (filterConfig.type == "person") {
        return value.value.map(v => v.person.id);
    } else if (filterConfig.type == "visibleInNavigart") {
        return value.value.value;
    } else if (filterConfig.type == "requiresExport") {
        return value.value.value;
    } else if (filterConfig.type == "hasPreviews") {
        return value.value.value;
    } else if (filterConfig.type == "dictionary") {
        return value.value.map(v => v.dictionary.id);
    }
    return null
}
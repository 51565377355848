import React from "react";
import {BACKEND_URL} from "../../config";

export const membersListTableConfiguration = {
    id: "membersTableV32    ",
    manageColumns: true,
    columns: [
        {
            id: "preview",
            label: "preview",
            default: false,
            renderer: (artwork) =>

                artwork.previews && artwork.previews.length > 0 && artwork.previews[0] && artwork.previews[0].ref ?
                    <img className="img-preview-in-table"
                         src={BACKEND_URL + "asset/" + artwork.previews[0].ref.id + "/small/raw"}/> : null
            // <img className="img-preview-in-table" src={"http://pauart.pl/api/assets/" + artwork.previews[0].ref.id + "/small/raw"} /> : null
        },
        {
            id: "identifier",
            label: "identifier",
            default: true,
            required: true,
            minWidth: "170px"
        },
        {
            id: "firstName",
            label: "firstName",
            default: true,
            required: true,
            minWidth: "170px"
        },
        {
            id: "lastName",
            label: "lastName",
            default: true,
            required: true,
            minWidth: "170px"
        },
        {
            id: "dateOfBirth",
            label: "dateOfBirth",
            default: true,
            required: false,
            minWidth: "170px",
            renderer: (artwork) => renderPauDate(artwork.dateOfBirth),
        },
        {
            id: "dateOfDeath",
            label: "dateOfDeath",
            default: true,
            required: false,
            minWidth: "170px",
            renderer: (artwork) => renderPauDate(artwork.dateOfDeath),
        },
        // {
        //     id: "salutation",
        //     label: "salutation",
        //     renderer: (artwork) => dictionaryEntryLabel(artwork.salutation),
        //     trimToLength: 36
        // },
        // {
        //     id: "postCode",
        //     label: "postCode",
        //     trimToLength: 36
        // },
        // {
        //     id: "speciality",
        //     label: "speciality",
        //     renderer: (artwork) => contactListOfDicEntries(artwork.speciality),
        //     trimToLength: 36
        // },
        // {
        //     id: "department",
        //     label: "department",
        //     renderer: (artwork) => dictionaryEntryLabel(artwork.department),
        //     trimToLength: 36
        // },
    ],
    isSelectable: true,
    recordIdentifier: (artwork) => artwork.id,
    onRowClick: (artwork, history) => history.push("/member/edit/" + artwork.id),
    rowRendererHelper: (artwork) => {
        return {"deleted": artwork.deleted}
    }
}


function renderPauDate(date) {
    if (!date) return null

    let str = "";

    if (date.year) {
        str += (date.year).toString()
        if (date.month) {
            str += "-" + (date.month).toString().padStart(2, '0')
            if (date.day) {
                str += "-" + (date.day).toString().padStart(2, '0')
            }
        }
    }

    return str
}

function getAttr(object, attr) {
    return object && object.hasOwnProperty(attr) ? object[attr] : null
}


export function dictionaryEntryLabel(e) {
    return e ? e.labels && e.labels.pl ? e.labels.pl : e.id : ""
}

export function contactListOfDicEntries(entries) {
    if (!Array.isArray(entries)) {
        return null
    }

    return entries
        .filter(e => e !== null)
        .map(e => e.labels && e.labels.pl ? e.labels.pl : e.id)
        .join(', ')
}

export function contactListPersons(entries) {
    if (!Array.isArray(entries)) {
        return null
    }

    return entries
        .map(e => e.name ? e.name : e.id)
        .join(',')
}

export function contactListAuthors(entries) {
    if (!Array.isArray(entries)) {
        return null
    }

    return entries
        .filter(e => e != null && e.author)
        .map(e => e.author.name ? e.author.name : e.author.id)
        .join(', ')
}
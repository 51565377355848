import * as actions from '../actions/artworkActions'
import {ARTWORK_SET_INVENTORY_NUMBER_VALIDATION_RESULT} from '../actions/artworkActions'
import {
    ASSET_MODAL_PREVIEW,
    ASSET_MODAL_PREVIEW_SET_THUMBNAIL_SIZE,
    ASSET_REFRESH_PREVIEW,
    ASSET_REMOVE_DONE,
    ASSET_REORDER_DONE,
    ASSET_UPLOAD_DONE
} from "../actions/assetActions";

export const initialState = {
    isLoading: true,
    isError: false,
    artwork: null,
    children: [],
    parent: null,
    validationResults: {},
    assetModalPreview: null,
    enabledFields: {}
};

export default function artworks(state = initialState, action) {
    switch (action.type) {
        case actions.ARTWORK_DETAILS_GET_DATA_REQUESTED:
            return { ...state, isLoading: true, isError: false};

        case actions.ARTWORK_DETAILS_GET_DATA_DONE:
        case actions.ARTWORK_UPDATE_DONE:
            return { ...state, isLoading: false, artwork: action.payload };

        case actions.ARTWORK_CHILDREN_LOAD_DONE:
            return {...state, isLoading: false, children: action.payload};

        case actions.ARTWORK_PARENT_LOAD_DONE:
            return {...state, isLoading: false, parent: action.payload};

        case actions.ARTWORK_DETAILS_GET_DATA_FAILED:
            return {...state, isLoading: false, isError: true};

        case actions.ARTWORK_TOGGLE_EDIT:
            let {fieldID} = action.payload
            let enabledFields = {...state.enabledFields};
            enabledFields[fieldID] = !enabledFields[fieldID];

            return {...state, enabledFields: enabledFields};

        case actions.ARTWORK_SET_ATTRIBUTE:
            let {attribute, value} = action.payload;
            let nextArtwork = {...state.artwork};
            nextArtwork[attribute] = value;
            return {...state, artwork: nextArtwork};

        case actions.ARTWORK_ADD_ATTRIBUTE:
            let {attributeToSet} = action.payload;
            let nextArtwork2 = {...state.artwork};

            initAttrAsArray(nextArtwork2, attributeToSet);

            nextArtwork2[attributeToSet].push(action.payload.value);
            return {...state, artwork: nextArtwork2};

        case actions.ARTWORK_REMOVE_FROM_ATTRIBUTE:
            let {idx} = action.payload;
            let attr4 = action.payload.attributeToSet
            let nextArtwork110 = {...state.artwork};

            nextArtwork110[attr4].splice(idx, 1)

            return {...state, artwork: nextArtwork110};

        case actions.ARTWORK_SET_ATTRIBUTE_SECOND_LVL:
            let {attr, subattr, val} = action.payload;
            let artwork = {...state.artwork};

            initAttrAsObject(artwork, attr);

            artwork[attr][subattr] = val;
            return {...state, artwork: artwork};

        case actions.ARTWORK_SET_ATTRIBUTE_THIRD_LVL:
            return setThirdLevel(state, action.payload);


        case actions.ARTWORK_ADD_ATTRIBUTE_SECOND_LVL:
            let artworkNew = {...state.artwork};

            initSecondLevelAttrAsArray(artworkNew, action.payload.attr, action.payload.subattr);

            artworkNew[action.payload.attr][action.payload.subattr].push(action.payload.val);
            return {...state, artwork: artworkNew};


        case actions.ARTWORK_SET_CARD_PREPARATION_DATE:
            let {dateType, yearMonthDay, datePartValue} = action.payload;

            let newArtwork = {...state.artwork};
            initSecondLevelAttrAsObject(newArtwork, "cardPreparation", dateType);

            if (!newArtwork.cardPreparation[dateType].from) {
                newArtwork.cardPreparation[dateType].from = {
                    year: null,
                    month: null,
                    day: null
                };
            }

            newArtwork.cardPreparation[dateType].from[yearMonthDay] = datePartValue;
            return {...state, artwork: newArtwork};

        case ASSET_UPLOAD_DONE:
        case ASSET_REORDER_DONE:
        case ASSET_REMOVE_DONE:
            return {...state, artwork: {...state.artwork, previews: action.payload}};
        case ASSET_REFRESH_PREVIEW:
            return assetRefreshPreview(state, action.payload);

        case ARTWORK_SET_INVENTORY_NUMBER_VALIDATION_RESULT:
            return setValidationResult(state, action.payload)

        case ASSET_MODAL_PREVIEW: {
            return modalPreview(state, action.payload)
        }
        case ASSET_MODAL_PREVIEW_SET_THUMBNAIL_SIZE: {
            return modalPreviewSize(state, action.payload)
        }
        default:
            return state;
    }
}

function setThirdLevel(state, payload) {
    let {attr, subattr, attrLevel3, value} = payload;
    let artwork = {...state.artwork};

    initAttrAsObject(artwork, attr);

    if (!artwork[attr][subattr]) {
        artwork[attr][subattr] = {}
    }

    artwork[attr][subattr][attrLevel3] = value

    return {...state, artwork: artwork};
}

function initAttrAsArray(artwork, attr) {
    if (!artwork[attr]) {
        artwork[attr] = [];
    }
}

function initAttrAsObject(artwork, attr,) {
    if (!artwork[attr]) {
        artwork[attr] = {};
    }
}

function initSecondLevelAttrAsArray(artwork, attr, subattr) {
    initAttrAsObject(artwork, attr);

    if (!artwork[attr][subattr]) {
        artwork[attr][subattr] = [];
    }
}

function initSecondLevelAttrAsObject(artwork, attr, subattr) {
    initAttrAsObject(artwork, attr);

    if (!artwork[attr][subattr]) {
        artwork[attr][subattr] = {};
    }
}

function setValidationResult(state, payload) {
    let validationResult = {...state.validationResults};

    validationResult[payload.path] = payload.message

    return {...state, validationResults: validationResult};
}

function assetRefreshPreview(state, payload) {
    let {assetId} = payload;
    let idx = state.artwork.previews.findIndex(prev => prev.ref.id === assetId)
    if (idx < 0) {
        return state;
    }

    let prevNewRef = {...state.artwork.previews[idx].ref, refresh: new Date().getTime()};
    let prevNew = {...state.artwork.previews[idx], ref: prevNewRef}

    let artworkPreviews = [...state.artwork.previews];
    artworkPreviews[idx] = prevNew;

    return {...state, artwork: {...state.artwork, previews: artworkPreviews}};
}

function modalPreview(state, payload) {
    if (!payload) {
        return {...state, assetModalPreview: null}
    }

    return {...state, assetModalPreview: payload}
}


function modalPreviewSize(state, payload) {
    let assetModalPreview = {...state.assetModalPreview}
    assetModalPreview.thumbnailSize = payload.thumbnailSize;

    return {...state, assetModalPreview: assetModalPreview}
}

export const selectFieldEditEnabled = (state, fieldId) => state.artworks.enabledFields && state.artworks.enabledFields[fieldId] == true
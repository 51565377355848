import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    pl: {
        translation: {
            "dateOfCreation": "Data powstania",
            "placeOfCreation": "Miejsce powstania",
            "placeOfCreation.city": "Miasto/Miejsce",
            "placeOfCreation.unknown": "Nieznane",
            "placeOfCreation.country": "Kraj",
            "placeOfCreation.comment": "Komentarz",
            "objectTypes": "Obiekt",
            "inscription.inscription": "Inskrypcja",
            "dimensions": "Wymiary",
            "materialTechnique": "Materiał/Technika",
            "materialTechnique.materials": "Materiał",
            "materialTechnique.techniques": "Technika",
            "materialTechnique.filigran": "Filigran",
            "provenance": "Proweniencja",
            "description.description": "Opis",
            "description.references": "Bibliografia",
            "description.tags": "Tagi",
            "copyright": "Prawa autorskie",
            "location": "Lokalizacja",
            "locations": "Lokalizacja",
            "ownership": "Własność",
            "iconclass": "Iconclass",
            "inventoryNumber": "Numer inwentarzowy",
            "title": "Tytuł",
            "otherAuthors": "Inni autorzy",
            "author.autorWzoruPosredniego": "Autor wzoru pośredniego",
            "author.autorWzoruBezposredniego": "Autor wzoru bezpośredniego",
            "author.autorTekstu": "Autor tekstu",
            "author.wydawca": "Wydawca",
            "cardPreparation": "Opracowanie karty",
            "cardPreparation.approver": "Zatwierdził",
            "cardPreparation.approveDate": "Data zatwierdzenia",
            "cardPreparation.author": "Opracował",
            "cardPreparation.authoredDate": "Data opracowania",
            "folders": "Kolekcje",
            "visibleInNavigart": "Widoczność w navigart",
            "requiresExport": "Wymaga akutalizacji w navigart",
            "visibleInNavigart.export": "Widoczny",
            "storage": "Przechowywanie w magazynie",
            "authors": "Autor",
            "assetsHeader": "Obrazy",
            "addImage": "Dodaj obraz",
            "search": "Szukaj",
            "phrase": "Fraza",
            "new...": "Nowy...",
            "exportStatus": "Status",
            "documentIsNotSaved": "Rekord nie został zapisany",
            "saveNewArtworkThenUploadImages": "Dodawanie obrazów jest możliwe po zapisaniu rekordu",
            "childrenArtworks": "Karty podrzędne",
            "parentArtwork": "Karta nadrzędna",
            "inventoryNumberIsNotUnique": "Podany numer inwentarzowy nie jest unikalny",
            "inventoryNumberIsEmpty": "Pole nie może być puste ",
            "titleIsEmpty": "Pole nie może być puste ",
            "titlePl": "Tytuł PL",
            "titleEn": "Tytuł EN",
            "at": "Czas",
            "user": "Użytkownik",
            "preview": "Podgląd",
            "system.dateCreated": "Sys. data utworzenia",
            "system.dateModified": "Sys. data modyfikacji",
            "system.modifier": "Sys. autor zmiany",
            "system.creator": "Sys. utworzono przez",
            "projects": "Projekt",
            "project": "Projekt",
            "selectFilter": "Zdefiniuj kryteria wyszukiwania",
            "cancel": "Anuluj",
            "appliedFilters": "Zastosowane filtry",
            "clear": "Wyczyść",
            "save": "Zapisz",
            "saveAs": "Zapisz jako...",
            "saveQuery": "Zapisz kwerendę",
            "queries": "Kwerendy",
            "filters": "Filtry",
            "more": "więcej",
            "documentIsDeleted": "Rekord został usunięty",
            "hasPreviews": "Posiada obrazy",
            "content": "Treść",
            "newsCreate": "Tworzenie aktualności",
            "newsEdit": "Edycja aktualności",
            "lang": "Wersje językowe",
            "datePublished": "Data publikacji",
            "newsList": "Lista aktualności",
            "contentList": "Lista treści",
            "contentCreate": "Dodawanie treści",
            "contentEdit": "Edycja treści",
            "name": "Nazwa",
            "description": "Opis",
            "personEdit": "Edycja osoby",
            "personCreate": "Dodawanie osoby",
            "dictionary": "Słownik",
            "dictionaryEntryEdit": "Edycja wpisu słownikowego",
            "dictionaryEntryCreate": "Tworzenie wpisu słownikowego",
            "financedBy": "Finansowany przez",
            "dictionary.acquisitionMethods": "Metody pozyskania",
            "dictionary.attachmentTypes": "Typ załacznika",
            "dictionary.cities": "Miasto",
            "dictionary.countries": "Kraj",
            "dictionary.currencies": "Waluta",
            "dictionary.dates": "Data",
            "dictionary.documentationTypes": "Typ dokumentacji",
            "dictionary.filigrans": "Filigran",
            "dictionary.genders": "Płeć",
            "dictionary.iconclass": "Iconclass",
            "dictionary.inventoryNumberTypes": "Typ numeru inwentarzowego",
            "dictionary.languageVersions": "Wersja językowa",
            "dictionary.languages": "Język",
            "dictionary.locations": "Lokalizacja",
            "dictionary.materials": "Materiał",
            "dictionary.nationalities": "Narodowość",
            "dictionary.objectTypes": "Typ obiektu",
            "dictionary.ownership": "Własność",
            "dictionary.provenance": "Prowiniencja",
            "dictionary.references": "Referencje",
            "dictionary.tags": "Tag",
            "dictionary.techniques": "Technika",
            "dictionary.projects": "Projekt",
            "Security": "Bezpieczeństwo",
            "Users": "Użytkownicy",
            "User Groups": "Grupy użytkowników",
            "Dictionaries": "Słowniki",
            "Entries": "Wpisy",
            "Assets": "Pliki",
            "Repositories": "Repozytoria",
            "Navigart": "Navigart",
            "Folders": "Foldery",
            "Import": "Import",
            "Database": "Baza danych",
            "roles": "Role",
            "role": "Rola",
            "usergroups": "Grupy",
            "locked": "Zablokowany",
            "cardPreparation.cardAuthoredDate.from.year": "Data opracowania (rok)",
            "start_test_import": "Testuj import",
            "start_import": "Importuj",
            "OK": "ok",
            "ARTWORK_DOES_NOT_EXIST": "Rekord nie istnieje",
            "ASSET_ALREADY_ATTACHED": "Plik jest juz podłączony do rekordu",
            "ASSET_UPLOAD_FAILED": "Wystąpił błąd podczas podłączania pliku",
            "No files found.": "Nie odnaleziono plików",
            "importStatus": "Status importu",
            "filename": "Nazwa pliku",
            "importResultsBelow": "Wyniki:",
            "artworkId": "Identyfikator rekordu",
            "dateTime": "Data",
            "history": "Historia",


            "salutation": "Zwrot grzecznościowy",
            "firstName": "Imię",
            "lastName": "Nazwisko",
            "dateOfBirth": "Data urodzenia",
            "dateOfDeath": "Data śmierci",
            "street": "Ulica",
            "postalCode": "Kod pocztowy",
            "city": "Miasto",
            "country": "Kraj",
            "speciality": "Specjalność naukowa/artystyczna",
            "committees": "Członkostwo w komisjach",
            "retired": "Emeryt",
            "phonePersonal": "Tel. dom.",
            "phoneWork": "Tel. służbowy",
            "phoneMobile": "Tel. kom.",
            "membershipAuPau": "Członkowstwo AU lub PAU",
            "department": "Wydział",
            "membershipTypes": "Typ członkostwa",
            "instituteName": "Instytut",
            "dead": "Nieżyjący",
            "personalData": "Osoba",
            "address": "Adres",
            "contact": "Kontakt",
            "memberRoles": "Pełnione funkcje",
            "memberOfDepartment": "Wydział",
            "from": "Od",
            "to": "Do",
            "institution": "Instytucja",
            "institutionName": "Instytucja",
            "members_city": "Miasto",
            "members_department": "Wydział",
            "membershipComments": "Komentarz",
            "email": "E-mail",
            "identifier": "Identyfikator",
            "homeAddress": "Adres zamieszkania",
            "correspondenceAddress": "Adres do korespondencji",
            "lifePeriodComment": "Komentarz",
            "phone": "Telefon",
            "rolesComment": "Komentarz",

            "homeAddress.street": "Adres zamieszkania - ulica",
            "homeAddress.postalCode": "Adres zamieszkania - kod pocztowy",
            "homeAddress.city": "Adres zamieszkania - miasto",
            "homeAddress.country": "Adres zamieszkania - kraj",

            "correspondenceAddress.street": "Adres do koresponencji - ulica",
            "correspondenceAddress.postalCode": "Adres do koresponencji - kod pocztowy",
            "correspondenceAddress.city": "Adres do koresponencji - miasto",
            "correspondenceAddress.country": "Adres do koresponencji - kraj",

        }

//dzieło nie istnieje - rekord nie istnieje
//w przypadku kiedy juz istnieje plik - zaznaczyc ta informacje
//rekord iestnieje
//rekord ma nie tworzyc sie w przypadku jego braku
//bulk edit
//walidacja
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "pl",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
export const DICTIONARY_ENTRY_OPEN_CREATE_MODAL = 'DICTIONARY_ENTRY_OPEN_CREATE_MODAL';
export const DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_REQUESTED = 'DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_REQUESTED';
export const DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_DONE = 'DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_DONE';
export const DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_FAILED = 'DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_FAILED';

export function isObject(val) {
    return (typeof val === 'object');

}

export function openCreateDictionaryEntryModal(dictionaryType, label, nextActionDetails) {

    return {
        type: DICTIONARY_ENTRY_OPEN_CREATE_MODAL,
        payload: {dictionaryType, label: (typeof label === 'object') ? label.value : label, nextActionDetails}
    };
}



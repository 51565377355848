import React from 'react'
import {connect} from "react-redux";
import {editFilter, setFilteringParam} from "../../actions/filtersActions";
import {Translation} from "react-i18next";
import {contactListOfDicEntries, contactListPersons} from "./artworksListTable.config";
import {setPageAndSearch} from "../../actions/browseActions";
import {ARTWORKS} from "./BrowseType";

const mapStateToProps = (state, ownProps) => {
    return {}
};
const mapDispatchToProps = (dispatch) => {
    return {
        unsetFilteringParam: (id) => {
            dispatch(setFilteringParam(id, null))
            dispatch(setPageAndSearch(0, ARTWORKS))
        },
        editFilter: (config) => dispatch(editFilter(config)),
    }
};

class FilterBadge extends React.Component {


    render() {
        let value = this.props.filterValue.value

        let valueToDisplay = null;
        if (this.props.filter.type === "string" || this.props.filter.type === "number") {
            valueToDisplay = value
        } else if (this.props.filter.type === "dictionaryEntry") {
            valueToDisplay = contactListOfDicEntries(value.map(e => e.dictionaryEntry))
        } else if (this.props.filter.type === "dictionary") {
            valueToDisplay = contactListOfDicEntries(value.map(e => e.dictionary))
        } else if (this.props.filter.type === "person") {
            valueToDisplay = contactListPersons(value.map(e => e.person))
        } else if (this.props.filter.type === "folder") {
            valueToDisplay = contactListOfDicEntries(value.map(e => e.value))
        } else if (this.props.filter.type === "visibleInNavigart") {
            valueToDisplay = value.value ? "Yes" : "No"
        } else if (this.props.filter.type === "requiresExport") {
            valueToDisplay = value.value ? "Yes" : "No"
        } else if (this.props.filter.type === "hasPreviews") {
            valueToDisplay = value.value ? "Yes" : "No"
        } else if (this.props.filter.type === "bool") {
            valueToDisplay = value ? "tak" : "nie"
        }

        return (<Translation>{t =>
                <span className="label">
                    <span onClick={() => this.props.editFilter(this.props.filter)}>
                        {t(this.props.filter.label)}: <i>{valueToDisplay}</i>
                    </span>
                    <i className="fas fa-times delete-filter"
                       onClick={() => this.props.unsetFilteringParam(this.props.filter.id)}></i>
                </span>
            }</Translation>
        )

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBadge)


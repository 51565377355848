import React from 'react'
import {connect} from "react-redux";
import getObjectAttr from "../../util/getObjectAttr";
import AsyncCreatableSelect from "react-select/async-creatable";
import {getDictionaryEntriesByLabel} from "../../api/dictionaryEntry.api";
import {setAttribute} from "../../actions/artworkActions";
import {viewToDbMapper} from "../../components/item/OtherAuthorsEditableField";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";


const mapStateToProps = (state, ownProps) => {
    let list = getObjectAttr(ownProps.path, state.artworks.artwork);
    return {
        enabled: "enabledOverride" in ownProps ? ownProps.enabledOverride : selectFieldEditEnabled(state, ownProps.path),
        isMulti: ownProps.isMulti == true,
        field: ownProps.isMulti ? (Array.isArray(list) ? list.map((dictionaryEntry) => {
            return toSelectizeDictionaryEntry(dictionaryEntry)
        }) : []) : list ? toSelectizeDictionaryEntry(list) : null,
        label: ownProps.label ? ownProps.label : ownProps.path
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAttribute: (attribute, value) => dispatch(setAttribute(attribute, value)),
        openCreateDictionaryEntryModal: (dictionaryType, label, isSecondary, attrToSet, subattrToSet, path, isMulti) => {
            // let actionTYpe = isMulti ? ARTWORK_ADD_ATTRIBUTE : ARTWORK_SET_ATTRIBUTE
            let payload = {
                attributeToSet: dictionaryType,
            }

            // if (isSecondary) {
            //     actionTYpe = ARTWORK_SET_ATTRIBUTE_SECOND_LVL
            //     payload = {
            //         attr: attrToSet,
            //         subattr: subattrToSet,
            //
            //     }
            // }

            let nextAction = setAttribute(path, null);
            nextAction.actionType = nextAction.type


            dispatch(openCreateDictionaryEntryModal(dictionaryType, label,
                nextAction
            ))
        },
    }
};


class SelectizeEditableFieldInline extends React.Component {

    handleChange = (inputValue, actionMeta) => {

        switch (actionMeta.action) {
            case "create-option":
                let label = this.props.isMulti ? inputValue[inputValue.length - 1].label : inputValue;
                this.props.openCreateDictionaryEntryModal(this.props.dictionaryId ? this.props.dictionaryId : this.props.path, label, this.props.isSecondary, this.props.attrToSet, this.props.subattrToSet, this.props.path);
                break;
            case "clear":
                this.props.setAttribute(this.props.path, null);
                break;
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setAttribute(this.props.path, this.props.isMulti ? viewToDbMapper(inputValue) : inputValue.dictionaryEntry);
            default:
                break;
        }

    };

    promiseOptions = inputValue => {
        let dictionaryId = this.props.path;
        if (this.props.dictionaryId) {
            dictionaryId = this.props.dictionaryId
        }
        return getDictionaryEntriesByLabel(dictionaryId, inputValue);
    };

    render() {

        let props = null
        if (this.props.hideSelectArrow) {
            props = {
                components: {DropdownIndicator: () => null, IndicatorSeparator: () => null}
            }
        }

        return (
            <Translation>{t =>
                <div className={`row form-line`}>
                    <div className="col-lg-2 form-label">{t(this.props.label)}</div>
                    <div className="col">
                        <AsyncCreatableSelect
                            {...props}
                            formatOptionLabel={function (data) {
                                return (
                                    <span dangerouslySetInnerHTML={{__html: data.label}}/>
                                );
                            }}
                            isClearable
                            placeholder={""}
                            value={this.props.field}
                            onChange={this.handleChange}
                            isMulti={this.props.isMulti}
                            isDisabled={!this.props.enabled}
                            defaultOptions
                            loadOptions={this.promiseOptions}/>
                    </div>
                </div>

            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectizeEditableFieldInline)

